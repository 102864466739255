const BLOG_COUNTER_DATA_LIST = getBlogCounterData();

function getBlogCounterData() {

    /*
    const DJANGO_CMS_BLOG_APP_ARTICLE = {
        // Represent an article object 'djangocms blog app'.
        id: '',
        title: '',
        subtitle: '',
    };
    */
    const blogCounterElementList = document.querySelectorAll('.card-post-item-counter__number');
    return Array.from(blogCounterElementList).map(blogCounter => {
        return {
            blogId: blogCounter.dataset.blogCounterId,
            blogTitle: blogCounter.dataset.blogCounterTitle,
            blogSubtitle: blogCounter.dataset.blogCounterSubtitle,
        };
    });
}

export {
    BLOG_COUNTER_DATA_LIST,
};
