import 'core-js/stable';
import 'regenerator-runtime/runtime';
// This import is used by djangocms_bootstrap4. Because the js bootstrap is V.5
// I have adapted the class names and data attribute to be compatible.
// For the adaption of the css see the comment in scss/components/_carousels.scss
import "bootstrap/js/dist/carousel";
import "./carousel";

import {
    ContentAnimation,
    contentAnimationOnScroll,
    contentAnimationOnScrollMobile,
    whenScrolledToNumberCounterContainer
} from "./scroll";
import {SelectOptions} from "./selectOptions";
import {StickyHeader} from "./stickyHeader";
import {Modal} from "./modal";
import {Gallery} from "./gallery";
import {SnackBar} from "./snackBar";
import {Menu} from "./menu";
import {getWindowWidth} from "./helpers";

window.addEventListener('load', function () {
    const contentAnimation = new ContentAnimation();
    contentAnimation.int();
    whenScrolledToNumberCounterContainer();

    const selectOption = new SelectOptions();
    selectOption.init();

    const stickyHeader = new StickyHeader();
    stickyHeader.init();

    const modalEmbedPDF = new Modal();
    modalEmbedPDF.addListenerToButtons(modalEmbedPDF);

    const modalForm = new Modal('form');
    modalForm.addListenerToButtons(modalForm);

    const gallery = new Gallery();
    gallery.displayInfo();

    const menu = new Menu();
    menu.onCheck();
});

window.addEventListener('DOMContentLoaded', function () {
    const snackBar = new SnackBar();
    snackBar.openClose();
});
