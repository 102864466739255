class SelectOptions {

    constructor() {
        this.wrapperClass = 'select-wrapper';
        this.selectClass = 'select';
        this.triggerClass = 'select__trigger > span';
        this.optionClass = 'select__option';
    }

    getDOMElements() {
        // Get dom element and return true only if all necessary elements are present.
        this.wrapperEl = document.querySelector(`.${this.wrapperClass}`);
        if (this.wrapperEl) {
            this.selectEl = this.wrapperEl.querySelector(`.${this.selectClass}`);
            this.optionsEl = this.wrapperEl.querySelectorAll(`.${this.optionClass}`);
            this.triggerEl = this.wrapperEl.querySelector(`.${this.triggerClass}`);
        }

        if (this.wrapperEl && this.selectEl && this.optionsEl && this.triggerEl)
            return true;
    }

    triggerOpenClose() {
        this.wrapperEl.addEventListener('click',
            (e) => this.selectEl.classList.toggle(CSS_STATUS_CLASS.open));
        window.addEventListener('click',
            (e) => {
                // Remove the class 'open' when the use click anywhere outside.
                if (e.target.closest(`.${this.wrapperClass}`) !== this.wrapperEl) {
                    this.selectEl.classList.remove(CSS_STATUS_CLASS.open);
                }
            });
    }

    setTriggerValue() {
        // In the 'wrapperEl' we must set a custom attribute
        // 'data-selected-option'. This value is set from the back-end.
        this.triggerEl.textContent = this.wrapperEl.dataset.selectedOption;
    }

    selectOption() {
        const selected = Array.from(this.optionsEl).find(
            el => el.textContent.trim() === this.wrapperEl.dataset.selectedOption.trim()
        );
        selected.classList.add(CSS_STATUS_CLASS.selected);
    }

    init() {
        // guard clause
        if (!this.getDOMElements()) return;

        this.triggerOpenClose();
        this.setTriggerValue();
        this.selectOption();
    }
}

export {SelectOptions};
