import Swiper, {Navigation, Pagination} from "swiper/swiper-bundle";

Swiper.use([Navigation, Pagination]);

const breakpoints = {
        300: {
            slidesPerView: 1,
            spaceBetween: 0,
        },
        600: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        900: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
        1100: {
            slidesPerView: 4,
        },
    };

const swiper = new Swiper('.swiper', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    slidesPerView: 4,
    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    breakpoints: breakpoints
});

const swiperWithNavigations = new Swiper('.swiper--with-navigations', {
    slidesPerView: 4,
    breakpoints: breakpoints,
    navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
});

export {swiper, swiperWithNavigations};