class Gallery {

    constructor() {
        this.allGallery = document.querySelectorAll('.gallery-card .light-gallery');
    }

    displayInfo() {
        if (this.allGallery) {
            for (const gallery of this.allGallery) {

                let allLink = document.querySelectorAll(`#${gallery.id} a`);
                let counterTextTag = document.querySelector(`#${gallery.id} .light-gallery__counter-js`);

                if (allLink) {
                    let lengthLink = allLink.length.toString(10);
                    counterTextTag.textContent = `1 / ${lengthLink}`;
                }
            }
        }
    }
}

export {Gallery};
