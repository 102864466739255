import {CSS_STATUS_CLASS} from "./config";

class Menu {

    constructor() {
        this.openCloseCheckboxSelector = 'navigation__checkbox';

        //    Select body element to change overflow whe menu is opened
        this.bodyEl = document.querySelector('body');
    }

    onCheck() {
        const checkBoxEl = document.querySelector(`.${this.openCloseCheckboxSelector}`);
        checkBoxEl.addEventListener('change', () => {
            this.bodyEl.classList.toggle(CSS_STATUS_CLASS.menuOpen);
        });
    }
}

export {Menu}