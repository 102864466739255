import {CSS_STATUS_CLASS} from "./config";

class StickyHeader {

    constructor() {
        this.intersectEl = document.querySelector(`.carousel`);
        this.affectedEl = document.querySelector(`.wrapper-home--header`);
    }

    init() {
        // Guard clause
        // (if we meet this condition we can't execute the rest)
        if (!this.intersectEl || !this.affectedEl) return;

        // To have a nice effect we need the height. So we add the class (when
        // the condition is reached) - (affectedEl height)
        const affectedElHeight = this.affectedEl.getBoundingClientRect().height;

        const stickyAffectedEl = function (entries) {
            const [entry] = entries;
            if (entry.isIntersecting) this.affectedEl.classList.remove(CSS_STATUS_CLASS.sticky);
            else this.affectedEl.classList.add(CSS_STATUS_CLASS.sticky);
        };

        const intersectObserver = new IntersectionObserver(
            stickyAffectedEl.bind({affectedEl: this.affectedEl}),
            {
                root: null,
                rootMargin: `-${affectedElHeight}px`,
                threshold: 0,
            }
        );

        intersectObserver.observe(this.intersectEl);
    }
}

export {StickyHeader};
