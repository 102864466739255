////////////////////////////////////////////////////
// FUNCTIONS
const extractValuesAndSymbol = function (str) {
    // PARAMETER
    // str: is a string which can have only 'value' or
    //      only 'symbol' or 'value' and 'symbol'
    // PROPERTIES
    // value: chars that represent numbers
    // symbol: chars that doesn't represent numbers
    // RETURNS
    // An object {value: value, symbol: symbol}
    // If value is empty means str has only symbol.
    // If symbol is empty means str has only value.
    let value = '';
    let symbol = '';

    for (let i = 0; i < str.length; i++) {
        const char = str[i];
        if (!(char >= '0' && char <= '9') && char !== '.') {
            symbol += char;
        } else {
            value += char;
        }
    }

    return {
        value: value,
        symbol: symbol
    };
};

const isFloat = function (value) {
    const num = Number(value);

    if (isNaN(num)) return false;
    if (num % 1 === 0) return false; //is an integer

    if (num % 1 !== 0) return true;
};

const getWindowWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
const getWindowHeight = () => window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

export {
    extractValuesAndSymbol,
    isFloat,
    getWindowWidth,
    getWindowHeight
};
