import {CSS_STATUS_CLASS, CSS_SNACKBAR_CLASS, LOCAL_STORAGE_KEYS_SNACKBAR} from "./config";

class SnackBar {

    constructor() {
        this.idSnackBarEl = 'snackBarForForm';
        this.idMessageEl = 'snackBarForFormMessage';
        this.idCloseEl = 'snackBarForFormClose';
        this.idAssociatedFormEl = 'cms-form';

        this.message = localStorage.getItem(LOCAL_STORAGE_KEYS_SNACKBAR.message) ||
            gettext('Thanks for contacting us!');
        this.classType = localStorage.getItem(LOCAL_STORAGE_KEYS_SNACKBAR.type) ||
            CSS_SNACKBAR_CLASS.type.success;
        this.classPosition = localStorage.getItem(LOCAL_STORAGE_KEYS_SNACKBAR.position) ||
            CSS_SNACKBAR_CLASS.position.bottomRight;
        this.duration = 8000;
    }

    openClose() {
        // Because for now a display the snackBar only after a page refresh
        // This method do the job based on the value that finds in the 'localstorage'.
        // So when we want to display the snackBar we need to set value for the
        // responsible localstorage key before calling this method.
        const snackBarEl = document.getElementById(this.idSnackBarEl);
        if (snackBarEl) snackBarEl.classList.add(
            localStorage.getItem(LOCAL_STORAGE_KEYS_SNACKBAR.type),
            localStorage.getItem(LOCAL_STORAGE_KEYS_SNACKBAR.position) || this.classPosition
        );
        const snackBarCloseEl = document.getElementById(this.idCloseEl);
        const snackBarMessageEl = document.getElementById(this.idMessageEl);

        if (snackBarCloseEl && snackBarMessageEl) {
            if (localStorage.getItem(LOCAL_STORAGE_KEYS_SNACKBAR.open) === 'true') {
                document.getElementById(this.idAssociatedFormEl).scrollIntoView({behavior: 'smooth'});
                snackBarMessageEl.textContent = localStorage.getItem(LOCAL_STORAGE_KEYS_SNACKBAR.message);
                snackBarEl.classList.add(CSS_STATUS_CLASS.open);

                setTimeout(function () {
                    localStorage.setItem(LOCAL_STORAGE_KEYS_SNACKBAR.open, 'false');
                    snackBarEl.classList.remove(CSS_STATUS_CLASS.open);
                }.bind(this), this.duration);

                snackBarCloseEl.addEventListener('click', function () {
                    snackBarEl.classList.remove(CSS_STATUS_CLASS.open);
                    localStorage.setItem(LOCAL_STORAGE_KEYS_SNACKBAR.open, 'false');
                });
            }
        }
    }
}

export {SnackBar};
