import {CSS_STATUS_CLASS, CSS_MODAL_CLASS, CSS_BUTTONS_CLASS} from "./config";

class Modal {

    constructor(type = 'embedPDF') {
        this.jsClassOverlayPane = 'overlay-pane-js';
        this.jsClassModal = 'modal-js';
        this.jsClassHeader = 'modal__header-js';
        this.jsClassClose = 'modal__btn-close-js';
        this.jsClassContent = 'modal__content-js';
        this.styleClassIframe = 'modal__iframe';
        this.type = type;

        if (this.type === 'form') {
            this.styleClassModal = CSS_MODAL_CLASS.form.modal;
            this.styleClassHeader = CSS_MODAL_CLASS.form.header;
            this.jsClassOpen = CSS_BUTTONS_CLASS.btnOpenModalForm;
        } else {
            this.styleClassModal = CSS_MODAL_CLASS.embedPDF.modal;
            this.styleClassHeader = CSS_MODAL_CLASS.embedPDF.header;
            this.jsClassOpen = CSS_BUTTONS_CLASS.btnOpenModalEmbed;
        }
    }

    openCLose() {
        const overlayPaneEl = document.querySelector(`.${this.jsClassOverlayPane}`);
        const btnCloseEl = document.querySelector(`.${this.jsClassClose}`);

        if (overlayPaneEl && btnCloseEl) {
            overlayPaneEl.classList.add(CSS_STATUS_CLASS.open);

            btnCloseEl.addEventListener('click', function (e) {
                overlayPaneEl.classList.remove(CSS_STATUS_CLASS.open);
            });

            overlayPaneEl.addEventListener('click', function (e) {
                if (e.target === overlayPaneEl) {
                    overlayPaneEl.classList.remove(CSS_STATUS_CLASS.open);
                }
            });

            document.addEventListener('keydown', function (e) {
                if (e.isComposing || e.key === 'Escape') {
                    overlayPaneEl.classList.remove(CSS_STATUS_CLASS.open);
                }
            });
        }
    }

    addIframeAsContent(event) {
        const modalContentEl = document.querySelector(`.${this.jsClassContent}`);
        // Create the iframe where we will put the embedded 'contact page'
        // from our djangocms website or the embedded PDF from google drive
        const iframe = document.createElement('iframe');

        // Remove previous children from content
        modalContentEl.innerHTML = '';
        iframe.classList.add(this.styleClassIframe);
        console.log(`styleClassIframe: ${this.styleClassIframe}`);
        iframe.src = event.target.href;
        modalContentEl.appendChild(iframe);
    }

    addClassStyle() {
        const modalEl = document.querySelector(`.${this.jsClassModal}`);
        const headerEl = document.querySelector(`.${this.jsClassHeader}`);

        if (modalEl && headerEl) {
            modalEl.classList.value = '';
            headerEl.classList.value = '';
            modalEl.classList.add(...this.styleClassModal);
            headerEl.classList.add(...this.styleClassHeader);
        }
    }

    addListenerToButtons(modal) {
        const buttonsEl = document.querySelectorAll(`.${this.jsClassOpen}`);
        if (buttonsEl) {
            buttonsEl.forEach(function (btn) {
                btn.addEventListener('click', function (e) {
                    e.preventDefault();
                    modal.addClassStyle();
                    modal.openCLose();
                    modal.addIframeAsContent(e);
                }.bind(this));
            });
        }
    }
}

export {Modal};
