import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import ScrollMagic from 'scrollmagic/scrollmagic/uncompressed/ScrollMagic';
import {CountUp} from "countup.js";
import {BLOG_COUNTER_DATA_LIST} from "./getHTMLData";
import {extractValuesAndSymbol, getWindowWidth, isFloat} from "./helpers";

class ContentAnimation {


    constructor() {
        this.containerSelector = 'animate-rash--container';
        this.itemSelector = 'animate-rash';
    }

    int() {
        this.magicCont = new ScrollMagic.Controller();
        if (getWindowWidth() >= 600) {
            this.containersEl = document.querySelectorAll(`.${this.containerSelector}`);
            this.onScroll();
        } else {
            this.itemsEl = document.querySelectorAll(`.${this.itemSelector}`);
            this.onScrollMobile();
        }
    }

    addAnimationClass(item) {
        item.classList.add('animate__fadeInUp', 'animate__animated');
    }

    removeAnimationClass(item) {
        item.classList.remove('animate__fadeInUp', 'animate__animated');
    }

    createMagicScene(triggerElement, offset = 0) {
        const magicScene = new ScrollMagic.Scene({
            triggerElement: triggerElement,
            reverse: false,
            offset: offset,
        });

        magicScene.addIndicators();

        return magicScene;
    }

    onScroll() {
        this.containersEl.forEach(container => container
            .querySelectorAll(`.${this.itemSelector}`)
            .forEach(item => this.removeAnimationClass(item)));

        this.containersEl.forEach(container => {

            const magicScene = this.createMagicScene(container, -400);

            magicScene.on('start', () => {
                const items = container.querySelectorAll(`.${this.itemSelector}`);
                items.forEach((item, index) => setTimeout(() =>
                        this.addAnimationClass(item),
                    index * 200
                ));
            });

            this.magicCont.addScene(magicScene);
        });
    }

    onScrollMobile() {
        this.itemsEl.forEach(item => this.removeAnimationClass(item));

        this.itemsEl.forEach(item => {
            const magicScene = this.createMagicScene(item, -400);
            magicScene.on('start', () => this.addAnimationClass(item));
            this.magicCont.addScene(magicScene);
        });
    }
}

function whenScrolledToNumberCounterContainer() {
    // We need to populate the array DJANGO_CMS_BLOG_APP_ARTICLES from html.
    // This is done by pushing a new DJANGO_CMS_BLOG_APP_ARTICLE object in the
    // array for every article of djangocms-blog app.
    // DJANGO_CMS_BLOG_APP_ARTICLE.id is needed to select the html tag
    // by class name where we will put the counter.
    // Because in html the class name is set:  number-counter__number{{ post.id }}--js
    // DJANGO_CMS_BLOG_APP_ARTICLE.subtitle is is needed to get the max value
    // of the counter (and to get if there is the measure symbol). It is necessary
    // to divide 'the value' from 'measure symbol' because we want to set to
    // different style for each

    // If in the page we actually we are, there is an element
    // with class name '.grid-rash-home' means we are in the
    // home_page template.
    // This check is needed because the element with class name
    // '.counters--js' which is the targetElement of the scene.
    // Now, it in the 'home_page.html' has some padding in
    // contrast with the 'others_page.html' which has no padding.
    // And so they have different offsets.
    // After some testing we have defined these.
    const offsetMagic = document.querySelector('.grid-rash-home') ? 0 : -165;

    const triggerEl = document.querySelector('.counters--js .animate-rash--container');
    if (!triggerEl) {
        return;
    }

    const magicController = new ScrollMagic.Controller();
    const magicScene = new ScrollMagic.Scene({
        triggerElement: '',
        offset: offsetMagic,
    });

    magicController.addScene(magicScene);

    magicScene.addIndicators();
    magicScene.on('start', function () {

        setTimeout(function () {
            BLOG_COUNTER_DATA_LIST.forEach((blog) => {
                const {value, symbol} = extractValuesAndSymbol(blog.blogSubtitle);
                const options = {
                    duration: 1,
                    separator: '',
                    suffix: `<span class="card-post-item-counter__measure-symbol">${symbol}</span>`
                };

                if (isFloat(value)) {
                    const pointIndex = value.indexOf('.');
                    options.decimalPlaces = value.slice(pointIndex).length - 1;
                }

                const counter = new CountUp(`counterNumber${blog.blogId}`, +value, options);
                if (!counter.error) {
                    counter.start();
                } else {
                    console.log(counter.error);
                }
            });

            magicScene.remove();

        }, 500);
    });
}

export {
    ContentAnimation,
    whenScrolledToNumberCounterContainer,
};
