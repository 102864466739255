const CSS_STATUS_CLASS = {
    open: 'open',
    menuOpen: 'menu-open',
    selected: 'selected',
    sticky: 'sticky',
};

const CSS_BUTTONS_CLASS = {
    btnOpenModalEmbed: 'btn-logo--embed-pdf-js',
    btnOpenModalForm: 'btn-logo--contact-form-js',
    btnSendMessage: 'btn--send-message-js',
};

const CSS_MODAL_CLASS = {
    embedPDF: {
        modal: ['modal', 'modal-js'],
        header: ['modal__header', 'modal__header-js'],
    },
    form: {
        modal: ['modal', 'modal--form', 'modal-js'],
        header: ['modal__header', 'modal--form__header', 'modal__header-js'],
    }
};

const CSS_SNACKBAR_CLASS = {
    type: {
        success: 'snack-bar--success',
        error: 'snack-bar--error',
    },
    position: {
        bottomRight: 'snack-bar--bottom-right',
        bottomLeft: 'snack-bar--bottom-left',
        bottomCenter: 'snack-bar--bottom-center',
        topRight: 'snack-bar--top-right',
        topLeft: 'snack-bar--top-left',
        topCenter: 'snack-bar--top-center',
        centerRight: 'snack-bar--center-right',
        centerLeft: 'snack-bar--center-left',
        centerCenter: 'snack-bar--center-center',
    }
};

const LOCAL_STORAGE_KEYS_SNACKBAR = {
    open: 'openSnackBar',
    message: 'messageSnackBar',
    type: 'typeSnackBar',
    position: 'positionSnackBar',
    // Display the snackbar with the appropriate message
    // (error / success) after the user has submitted the button
    // Possible value true or false.
    wasButtonSubmitted: 'wasButtonSubmittedSnackBar',
};

export {
    CSS_SNACKBAR_CLASS,
    CSS_STATUS_CLASS,
    CSS_MODAL_CLASS,
    CSS_BUTTONS_CLASS,
    LOCAL_STORAGE_KEYS_SNACKBAR
}
